import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { BannerDetails } from "../BannerDetails";
interface CollectionBannerDetailsProps {
    hasFiltersEnabled: boolean;
    onCollectionUpdateRules?: () => void;
}

export const CollectionBannerDetails = ({ hasFiltersEnabled, onCollectionUpdateRules }: CollectionBannerDetailsProps) => {
    return (
        <BannerDetails>
            This collection has{" "}
            <InlineLink
                onClick={() => {
                    onCollectionUpdateRules && onCollectionUpdateRules();
                }}
            >
                auto-updating {hasFiltersEnabled ? "enabled" : "disabled"}
            </InlineLink>
            .
        </BannerDetails>
    );
};
